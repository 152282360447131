.background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    margin: 0;
    padding:0;
    z-index: -100;
}

.privacy {
    display: flex;
    width: 800px;
    height: 657px;
    flex-direction: column;
    padding: 25px 30px;
    margin: 0 auto;
}

h1.privacy_h1 {
    font-size: 19px;
    font-weight: normal;
    line-height: 23px;
    margin-bottom: 5px;
    color: rgb(63, 63, 63)
}

strongest {
    font-style: inherit;
    font-weight: bold;
    color: rgb(63, 63, 63)
}


.heading_info {
    border-bottom: 1px solid rgb(63, 63, 63);
    padding-bottom: 25px;
    display: block;
    flex-direction: column;
}

.heading_info p{
    color: #6b6b6b;font-size: 14px;
}

.privacy_2nd {
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    padding-top: 21px;
    color: #3f3f3f;;
    display: flex;
    margin: 0;
}
ul.privacy_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 30px;
    height: 200px;
    border-bottom: 1px dotted #dfdfdf;
}
.privacy_li {
    max-width: 276px;
    display: flex;
    padding-left: 40px;
    flex-direction: column;
    background-repeat: no-repeat;
}

heading_t {
    font-size: 13px;
    font-weight: bold;
    color: #3f3f3f;
}
gr_t {
    padding-top: 10px;
    color: #787878;font-size: 13px;
    font-weight: bold;
    margin: 0;
}
.privacy_li_p {
    line-height: 19px;
    margin: 0;
    font-size: 13px;
    font-weight: normal;
}

#root > div.privacy > ul > li:nth-child(1) {
    background-image: url("./завантаження.png");
}
#root > div.privacy > ul > li:nth-child(2) {
    margin-top: 30px;
    background-image: url("./завантаження (2).png");
}
#root > div.privacy > ul > li:nth-child(3) {
    margin-top: -184px;
    margin-left: 400px;
    background-image: url("./завантаження (1).png");
}

#root > div.privacy > li {
    margin-top: 30px;
    background-image: url("./завантаження (3).png");
    padding-bottom: 20px;
    display: flex;
}
#root > div.privacy > div.iub_footer > a {
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
    color: #333b43;
    font-size: 11px;
}
.iub_footer {
    position: relative;

}

span.line {
    height: 1px;
    width: 100%;
    border-bottom: 1px dotted #dfdfdf;
}

.footer_privacy_p, .footer_privacy_a {
    line-height: 19px;
    margin: 0;
    font-weight: normal;font-size: 11px;
    color: #6b6b6b;
}

.iub_footer {
    padding-top: 10px;
}