.prof_card {
    display: flex;
    padding-bottom: 10px;
}

.prof_card img{
    width: 100px;
    object-fit: cover;
    padding-right: 20px;
}

name {
    display: flex;
    background-color: #ECF7F9;
    color: #2886c4;
    padding-top: 5px;
    font-weight: 600;
    font-size: 14px;
    width: 580px;
}

.p_desc {
    margin: 0;
}