.home_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    background-color: white;
    border-radius: 12px;
    width: 1000px;
    position: relative;
    z-index: 1;
    height: auto;
}

.content_filling {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.column_1 {
    width: 400px;
    display: flex;
    flex-direction: column;
}

h1.heading {
    border-bottom: 1px solid #939393;
    color: #939393;
    font-size: 22px;
    font-weight: 300;
    font-family: Arial, sans;
    height: 32px;
}

.Testo {
    font-size: 12px;
}

strong {
    color: #2886c4;
    line-height: 16px;
    font-weight: bold;
}

.content_filling img {
    width: 300px;
    height: 125px;
}

img.leggi {
    width: 88px;
    height: 27px;
}

a.leggi {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    margin-bottom: 15px;
}

iframe {
    padding-top: 20px;
    padding-bottom: 20px;
}

.column_2{
    display: flex;
    flex-direction: column;
    width: 265px;
}

h1.heading_blue {
    border-bottom: 1px solid #939393;
    color: #2886C4;
    font-size: 22px;
    font-weight: 300;
    font-family: Arial, sans;
    height: 32px;
}

.box {
    margin-top: 10px;
    display: flex;
    border-bottom: 1px dashed #939393;
    padding-bottom: 10px;
}

#root > div > div.content > div.content_filling > div.column_2 > div > div:nth-child(1) > div > img {
    width: 63.5px;
    height: 90px;
}

.box_content strong{
    font-size:14px;
    font-weight:700;
}

number {
    color: #2886c4;
    line-height: 16px;
    padding-right: 10px;
    font-weight: 900;
}

div.leggi {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    text-decoration: none;

}
div.leggi a{
    color: #2886c4;
    font-weight: 600;
}

box_heading {
    color: #2886c4;
    line-height: 16px;
    font-weight: 100;

}

#root > div > div.content > div.content_filling > div.column_2 > div > div:nth-child(2) > div > img {
    width: 189px;
    height: 90px;
}

#root > div > div.content > div.content_filling > div.column_2 > div > div:nth-child(3) > div > img {
    width: 237px;
    height: 73px;
    border: 1px solid #2886c4
}

.boxes{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 557px;
}