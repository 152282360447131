body {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-image: url("./images/background.jpg");
  background-repeat: no-repeat;
  background-repeat: repeat-x;
  font-size: 12px;
  color: #3c3a3a;
  background-color: white;
}

a {
  text-decoration: none;
}

em {
  font-style: normal;
}

a.point.active {
  color: #2886c4;
}