.comitato_scientifico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comitato_scientifico_content {
    display: flex;
    background-color: white;
    border-radius: 12px;
    width: 1000px;
    position: relative;
    z-index: 1;
    height: auto;
}

.comitato_scientifico_filling {
    padding-top: 20px;
    padding-left: 20px;
    max-width: 700px;
    display: flex;
    justify-content: space-around;
}