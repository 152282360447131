.footer{
    padding-top: 20px;
    display: flex;
    width: 1000px;
    flex-direction: column;
    gap: 5px;
    height: 50px;
}

.privacy_pol, .marca {
    margin:0;
}

.privacy_pol a.footer_link {
    color: #2886c4;
    text-decoration: none;
    font-size: 11px;
    font-family: Arial;
    width: 10%;
}

.privacy_pol {
    font-size: 11px;
    color: #808080;
}

.marca {
    font-size: 11px;
    font-family: Arial;
    color: #808080;
}

.marca a {
    font-size: 11px;
    text-decoration: none;
    color: #808080;
    padding-bottom: 10px;
}