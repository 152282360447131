.menu {
    position: relative;
    width: 250px;
}

.menu_list {
    list-style-type: none; /* Remove default list item marker */
    padding-left: 0; /* Remove default padding */
}

.menu_list .point a::before {
    content: url("../images/marker.gif"); /* Use the marker image as content */
    margin-right: 5px; /* Adjust the spacing between the marker and the text */
}


ul.menu_list {
    width: 250px;
    height: 370px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    z-index: 10;
    box-shadow: 3px 4px 7.1px 0px rgba(0, 0, 0, 0.25);
    margin:0;
    padding-left: 0;
}

li.point {
    list-style-type: none;
}

li.point:first-child{
    margin-top: 30px;
}

a.point {
    padding-top: 8px;
    display: block;
    text-decoration: none;
    font-size: 13px;
    font-family: Arial;
    text-align: left;
    height: 22px;
    border-bottom: 1px dotted #3c3a3a;
    width: 180px;
    color: #3c3a3a;
}

.bottom_image_div {
    position: relative;
    display: flex;
    justify-content: center;
    width: 250px;
    height: 260px;
    border-radius: 12px;
    margin-top: -30px;
    background-color: #2886C4;
    z-index: -1;
}

.bottom_image {
    padding-bottom: 15px;
}

marker {
    width: 7px;
    height: 9px;
    background-image: url("../images/marker.gif");
}

a.point:hover {
    color: #2886c4;
}

a.facebook_link_image {
    width: 220px;
    height: 80px;
    position: absolute;
    top: 510px;
    left: 15px; /* Adjust as needed */
    z-index: 20; /* Higher z-index */
}

a.dalla_ricerca_link_image {
    width: 220px;
    height: 80px;
    position: absolute;
    top: 425px;
    left: 15px; /* Adjust as needed */
    z-index: 20; /* Higher z-index */
}