.dalla_ricerca {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dalla_ricerca_content {
    display: flex;
    background-color: white;
    border-radius: 12px;
    width: 1000px;
    position: relative;
    z-index: 1;
    height: auto;
}

.dalla_ricerca_filling {
    padding-top: 20px;
    padding-left: 20px;
    max-width: 700px;
    display: flex;
    justify-content: space-around;
}

strong {
    color: #2886c4;
    line-height: 16px;
    font-weight: bold;
}

a {
    color: #2886c4;
}